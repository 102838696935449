<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1562567825_1.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product2"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1562567835_2.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product3"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1562567842_3.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1562567825_1.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="thnine()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1562567835_2.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="tletha()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1562567842_3.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                
                                  </div>
                                  <div class="col" style="padding: 1px"></div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                         
AB part Glue dispenser Machine PGB-1200
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >PGB-1200</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >1.LED products: LED display , led screen , outdoor wall washer , outdoor rigid light , underground lamp , underwater light . <br> 2.Electronics parts : Micro-electronics, inductors, transformers, ballasts, electronic coils, transformers, relays, loudspeakers, heat kettles, inductors and so on . <br> 3. Electric vehicle: auto lamp ,generator, clutch, air conditioner filter, oil filter, car control card, horn, flasher , electric vehicle accessories. <br> 4.Communications parts : Communication box <br> 5.Water treatment: Water filter , water meter and so on . Other products need glue potting process .</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >AB crystal glue: Glue dropping application and potting application. AB glue potting for isolation (with fillers): Two components silicone , two parts of PU, AB glue epoxy and so on .</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/EU5ni-eEL4E" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />

                        <div class="row" style="font-size: 14px">
                          1、Glue controlling system of metering, mixing and potting.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、Accurate metering and even mixing helps to avoid
                          the waste of glue.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、Dispensing size and positioning keep machine work
                          consistent and fast.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          4、Good universality
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          5、 Easy to program and operate.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          6、Tally function, non-standard mode, primer mode,
                          level alert, filter mode, liquid display device,
                          preventing back-flow device and other functional
                          device.
                        </div>
                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row" style="font-size: 14px">
                  <img
                    src="../../../../assets/images/product/main/production_equipement/Glue_Dispensing_Machine/15pgb1200.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />
                </div>
                <div class="row" style="font-size: 14px">
                 

                  
                    <strong style="color: black"
                    >Application of filler glue potting:<br
                  /></strong>
                  <br>
                  1.LED products: LED display , led screen , outdoor wall washer , outdoor rigid light , underground lamp , underwater light . <br>

2.Electronics parts : Micro-electronics, inductors, transformers, ballasts, electronic coils, transformers, relays, loudspeakers, heat kettles, inductors and so on .<br>

3. Electric vehicle: auto lamp ,generator, clutch, air conditioner filter, oil filter, car control card, horn, flasher , electric vehicle accessories.<br>

4.Communications parts : Communication box<br>

5.Water treatment: Water filter , water meter and so on .<br>

Other products need glue potting process .<br>
 <img
                    src="https://v4-upload.goalsites.com/141/editor_1562139193_%E5%9B%BE%E7%89%874.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  />
                

                  1. Surface gluing ：flexible strips Photo albums, Mosaic
                  tiles, stickers, badges, refrigerator stickers, bra buttons,
                  insoles, snowboard, tile stickers and other crafts.<br />
                  2. Crystal glue potting for isolation: LED resin word, small
                  module, underwater lamp, vase , digital tube and other
                  products that need to potting for isolation .<br />
                  <img
                    src="https://v4-upload.goalsites.com/141/editor_1562136200_%E5%9B%BE%E7%89%871.jpg"
                    alt="img-1"
                    class="img-fluid mx-auto d-block rounded"
                  /><br />
                  <strong style="color: black"
                    >Machine functions: <br
                  /></strong>
                  <ol class="container">
                    <li>Our machine can be use for AB glue ratio between the base agent and hardener: 1:1</li>
                    <li>The double cylinder plunger pumps is designed for high abrasive glue materials. </li>
                    <li>Machine have vacuum functions that can remove the air bubble inside</li>
                    <li>AB tanks have stirrer to make sure AB glue not have sediment.</li>
                    <li>The mixed liquid is abrasive, cause big friction to the pump.</li>
                    <li>Need to vacuum to remove the air bubble inside</li>
                    <li>Not easy to mix the two components evenly.</li>
                    <li>Vacuum: to remove the air bubble inside the adhesive;</li>
                    <li>Self-cleaning；</li>
                    <li>Heating: pre-heat the resin before used</li>
                    <li>Dynamic Mixing Method to mix evenly;</li>
                    <li>Daheng Patent Metering Pump: anti-abrasive, long life span; high precision;Multi-nozzle: to improve capacity.</li>
                    <li>High Precision Valve;</li>
                    <li>Quality & Strict position control system.</li>
                    <li>Multi-head nozzle available.</li>
                  </ol>
                </div>
              </b-tab>
               <b-tab title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two
                    sets of metering pumps inside the machine, draining out
                    liquids individually from tank A & B. Liquid A & B mix with
                    each other in the mixer pipe and dispensed out according to
                    the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
               <b-tab title="Other information">
                <div class="row container">
                  <div class="col container">
                    <h1 style="color: black">
                      Advantage of automatic glue dispensing machine:
                    </h1>
                    <ol>
                      <li>Fully automatic operation, accurate proportion and even mixing</li>
                      <li>Glue dosing while mixing, no waste of glue and no waste of consumables. Precise dosing and dispensing.</li>
                      <li>The glue amount of the product is consistent and good. The edge of the machine is clean without glue.</li>
                      <li>Machine glue dosing save much time and effective and lower cost</li>
                      <li>There is no need for glue pouring staff, only one operator is needed to complete the mass production</li>
                    </ol>
                  
                  </div>
                </div>
                <br>
                <div class="row container">
                  <div class="col container">
                    <h1 style="color: black">
                      Normally technical issues should be solved to choose the glue potting machine
                    </h1>
                    <ol>
                      <li>Ratio between the base agent and hardener: 1:1 or 10:1</li>
                      <li>Fillers：Abrasive the base agent includes percentage of additives and fillings such as aluminum oxide, or silica powder, in order to increase the hardness or heat conduction </li>
                      <li>The mixed liquid is abrasive, cause big friction to the pump.</li>
                      <li>Need to vacuum to remove the air bubble inside</li>
                      <li>Not easy to mix the two components evenly.</li>
                      <li>Vacuum: to remove the air bubble inside the adhesive</li>
                      <li>Self-cleaning</li>
                      <li>Heating: pre-heat the resin before used</li>
                      <li>Dynamic Mixing Method to mix evenly</li>
                      <li>Daheng Patent Metering Pump: anti-abrasive, long life span; high precision;Multi-nozzle: to improve capacity</li>
                      <li>High Precision Valve</li>
                      <li>Quality & Strict position control system.</li>
                      <li>Multi-head nozzle available.</li>
                    </ol>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
      product2: false,
      product3: false,
    
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false);

    },
    thnine() {
      (this.product1 = false),
        (this.product2 = true),
        (this.product3 = false);
   
    },
    tletha() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = true);
        
    },
   

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>